<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="選んでください" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>ユーザチャージプロトコル</h2>
		<p>本チャージサービス契約（以下、「本契約」という）は、PIXTECHNOLOGY
			PTE.LTD下のUniLiveプラットフォームと締結し、プラットフォームは、本契約の約定および時々発行される操作規則に従ってサービス（以下「スタービーンズサービス」または「本サービス」）を提供します。</p>
		<p>
			ユーザーにより良いサービスを提供するために、サービス利用者（「糖果」を注文して購入したアカウント利用者、以下「あなた」）は、本サービスを利用する前に本契約書をよく読んで理解してください。その中で、プラットフォームの責任を免除または制限する条項は、重点的に読んでください。
		</p>
		<p>本契約に同意しない場合は、次の操作（チャージなどの操作ボタンをクリックしたり、支払いを行ったりすることを含むが、これらに限定されない）および本サービスの使用を一切行わないでください。</p>
		<p>
			プラットフォームは未成年者の糖果サービスの利用を禁止している。プラットフォームはここで好意的に注意して、もしあなたは未成年者の保護者のために、あなたはあなたが保護している未成年者に対して保護の職責を負担することに対応して、あなたは未成年者が本プラットフォームの関連製品とサービスを使用する時に青少年モード及び/またはその他の未成年者保護ツールをオンにして、未成年者が関連製品とサービスを正しく使用することを監督、指導して、同時にネット決済方式の制限と管理を強化して、共同で未成年者が健康に成長する
		</p>
		<h3>一、サービス内容</h3>
		<p>
			1、「糖果」はプラットフォームが提供する、プラットフォーム内で関連消費を行うだけの仮想ツールです。「糖果」を購入した後、プラットフォーム関連ページの説明やガイドラインに従って使用したり、プラットフォームの商品やサービスを取得したりすることができます。これには、生放送鑑賞サービス（ユーザーはキャスターに仮想プレゼントを贈ることでキャスターショーなどのサービスに対価を支払うことができます）などが含まれますが、これらに限定されません。疑問を持たないために、糖果サービスはユーザーが料金を支払い、プラットフォームがユーザーに提供し続ける全体的なネットワーク技術と関連サービスであり、糖果サービスはネットワーク決済サービスではなく、「糖果」もトークンチケット、仮想通貨、または前払金の証憑ではなく、貨幣価値、前払価格値を備えていない。
		</p>
		<p>2、「糖果」を購入した後は、本契約の条項の下の約束の目的に使用するだけで、会社が提供する製品/サービスの範囲外で使用したり、異なるUniLiveアカウント間で転送したりすることはできません。</p>
		<p>
			3、プラットフォームを通じて公式ルートを指定して「糖果」を購入するべきで、プラットフォームは会社の許可を得ていないルートを認めていません。もしあなたが非公式ルートを使って「糖果」を購入すると、プラットフォームはこの「糖果」があなたのアカウントにスムーズにアクセスできることを保証することができません。また、このような行為は詐欺、マネーロンダリングなどのリスクを伴い、あなた、プラットフォーム、関連第三者に取り返しのつかない損失や損害を与える可能性があります。そのため、非公式ルートの購入行為は違反とみなされ、プラットフォームはアカウント内の「糖果」を差し引いたりクリアしたり、アカウントのすべてまたは一部の機能を制限したり、アカウントを永久に閉鎖したりする権利があり、それによる損失を自分で負担しなければなりません。前述の約束に違反してプラットフォームまたはその他の第三者に損害を与えた場合は、賠償責任を負わなければなりません。天猫などの第三者プラットフォームに開設された公式店舗などでプラットフォームを通じて購入した場合、プラットフォームはあなたが指定した「糖果」を通じてアカウントの使用者情報および/または会社との別の合意（あれば）の中の約束を通じてサービス使用者を認定します。
		</p>
		<p>
			4、「糖果」を購入する費用は、会社または会社が指定したパートナーが受け取ります。プラットフォームは特に、異なる購入チャネルの関連サービスプロバイダが、自分の運営戦略に基づいて、料金を支払う際にチャネルサービス料を受け取る可能性があることを示しています。これにより、異なるチャネルを通じて同じ数量の「糖果」を購入する費用の金額や、同じ金額の費用を払って購入する「糖果」の数に差が出る可能性があります。具体的には、「糖果」を購入する際のページ表示に基づいています。関連ページ情報を確認し、「糖果」の購入ルートを合理的に選択することに注意してください。
		</p>
		<p>
			5、他人のアカウントのために「糖果」を購入しようとすると、プラットフォームは該当する数の「糖果」を指定したアカウントの項目に配布し、このアカウントの使用者は本契約の約束に基づいてプラットフォームが提供する糖果サービスを使用することができます。そのために紛争が発生した場合は、そのアカウント使用者と自分で協議して解決し、プラットフォームはあなたとそのアカウント使用者に責任を負う必要はありません。
		</p>
		<h3>二、理性的消費</h3>
		<p>1、プラットフォームは理性的な消費を提唱し、収入に応じて支出する。ご自身の消費能力と実際のニーズに合わせて、糖果サービスを合理的に購入し、使用して、過度な消費を回避してください。</p>
		<p>
			2、「糖果」を購入するために使用する資金は、合法的に獲得し、使用する権利のある収入でなければならない。本条に違反した場合、そのために発生した紛争や紛争は、プラットフォームや第三者に損害を与えた場合、全額賠償しなければならないなど、自分で解決し、法的結果を負担しなければなりません。もしプラットフォームが（自発的に発見し、第三者から苦情を受けたり、主管部門、司法機関から通知を受けたりするなどの状況を含むが、これに限らない）あなたが前述の約束に違反した疑いがある場合、プラットフォームはあなたのアカウント内の「糖果」を差し引いたり、空にしたり、あなたのアカウントのすべてまたは一部の機能を制限したり、永遠にあなたのアカウントを閉鎖したりする権利があります。同時にプラットフォームは関連情報を保存し、関連主管部門、司法機関に報告する権利がある。
		</p>
		<p>
			3、プラットフォームは誘導、刺激、ユーザーの非理性的な賞を扇動し、未成年者に虚偽の身分情報で賞を与える行為を誘惑し、教唆する行為を厳格に排斥する。前述の違反行為を発見した場合は、プラットフォーム公示チャネル（生中継間の右下-通報、UniLive生中継業界の自律プラットフォーム-通報など）に基づいてプラットフォームに通報することができ、プラットフォームは法に基づいて規則に基づいて処理措置をとることができます。あなたが私たちと一緒に健康で秩序あるコミュニティ生態を構築することを期待しています。
		</p>
		<h3>三、あなたの権利義務</h3>
		<p>1.本サービスの使用中に提供される個人情報または資料は、真実で正確であり、必要に応じて規制当局の調査に協力し、法律の規定およびプラットフォームの要求に従って協力しなければなりません。</p>
		<p>
			2、「糖果」を購入する際には、アカウント情報/アカウントバインディングメールボックス、「糖果」購入数などを慎重に選択および/または入力してください。もしあなた自身の入力ミス、操作が不適切で、料金計算方法などの要素を十分に理解していないためにアカウントの誤り、「糖果」の数量の誤りなどの購入ミスが発生した場合、そのためにあなたの損失や支出を招いた場合、プラットフォームは補償や賠償をしない権利があります。
		</p>
		<p>
			3、アカウントを適切に保管し、使用し、そのアカウントの下のすべての操作行為と結果に責任を負わなければなりません。次のような状況により、プラットフォームが本サービスを提供できなくなったり、本サービスを提供できなくなったりしたときにエラーが発生した場合、法律法規の明示的な要件以外のプラットフォームは法的責任を負いません。
		</p>
		<p>（1）アカウントの失効、紛失、盗難、閉鎖、</p>
		<p>（2）口座に縛られた第三者支払機関、銀行口座が凍結され、差し押さえられたり、他の異常が発生したり、認証されていない口座や本人ではない口座を使用したりしています。</p>
		<p>（3）アカウントのパスワードを他の人に知らせるか、他の方法でログインしてアカウントを使用できるようにしました。</p>
		<p>（4）故意または重大な過失がある場合。</p>
		<p>
			4、本サービスを合法的にコンプライアンスして使用するべきであり、本サービスをいかなる違法犯罪、公序良俗、有害な社会道徳風俗、またはプラットフォームの正常な運営を妨害し、第三者の合法的権益を侵害する目的に使用してはならず、本サービスを使用する行為も、あなたに拘束力のある書類やその他の要求（あれば）に違反してはならない。プラットフォームは特に、他人があなたのアカウントを通じて上記の約束に違反する行為を実施することを合理的に防止し、自分のアカウントと財産の安全を保護するために、あなたのアカウントを借りたり、譲渡したり、他の方法で他の人に提供したりしないように注意してください。
		</p>
		<p>
			5、プラットフォームは法に基づいて未成年者の消費返金サービスを提供し、未成年者とその保護者の合法的権益を保障する。大人が未成年者になりすまして返金をだまし取ったり、未成年者の消費を誘導して返金を申請したりするなど、違法な目的や不当な方法でサービスを使用してはいけません。前述の行為は本契約に対する重大な違反を構成し、プラットフォームは合理的に確認された後、返金を拒否する権利があり、さらに法に基づいてあなたの法的責任を追及する権利を保留する。
		</p>
		<p>
			6、あなたはこの専属アカウントを利用してマネーロンダリングなどのいかなる不法活動を行ってはならないことを承諾しなければならなくて、他の人があなたを利用してこの専属アカウントを通じてマネーロンダリングを完成するなどのいかなる不法な要求を受け入れてはいけません。上記の約束に違反した場合、プラットフォームはマネーロンダリング防止に関する法律規定及び/または主管部門、司法機関の要求に基づいてサービスの提供を一時停止し、調査を展開するなどの措置を取る権利があり、それによってあなたが被ったいかなる損失や損害を負担する必要はありません。
		</p>
		<p>
			7、本サービスの使用中に第三者によって提供された関連サービス（例えば支払サービスなど）に関連している場合、本契約の約定に加えて、第三者の合意及び関連規則に同意して遵守する必要があり、いかなる場合にも、第三者及びその提供された関連サービスについて発生した紛争は、あなた自身が第三者と解決する必要があり、プラットフォームはあなたまたは第三者に責任を負う必要はありません。
		</p>
		<h3>四、プラットフォーム権利義務</h3>
		<p>
			1、プラットフォームは法律法規の改正、監督管理部門の要求、取引安全保障、運営戦略の更新、市場環境の変化などの方面の考慮に基づいて、時々「糖果」サービスに関連制限、注意などを設置する権利があり、すべてまたは一部のユーザーの取引限度額および/または取引回数を制限すること、特定のユーザーの本サービスの使用を禁止すること、または取引検証を増加することなどを含むが、これらに限らない。
		</p>
		<p>
			2、取引の安全を保障するために、プラットフォームはまたあなたが本サービスを使用する行為を監視測定する権利があり、そのため合理的にリスクの高いユーザーまたはアカウントと認定し、プラットフォームはリスクの拡大を防止し、ユーザーの財産とプラットフォームの生態安全を保障するために必要な措置をとる可能性がある、このような必要な措置には、アカウント内の「糖果」の控除やクリア、アカウントのすべてまたは一部の機能の制限、アカウントの短期的または永久的な閉鎖などが含まれます。
		</p>
		<p>
			3、プラットフォームがシステム障害やその他の原因による処理ミスを発見した場合、プラットフォームがプラットフォームに有利であれ、あなたに有利であれ、プラットフォームはそのエラーを修正する権利がある。この場合、実際に受け取った「糖果」の数が獲得すべき「糖果」よりも少ない場合、プラットフォームは処理ミスを確認した後、できるだけ早く差額をアカウントに補充します。実際に受け取った「糖果」の数が獲得すべき「糖果」よりも多い場合、プラットフォームは事前に通知せずにアカウントから直接差額を控除する権利があります。
		</p>
		<p>
			4、プラットフォームは、取引の安全、運営計画、国家法律法規または監督管理部門の要求などの具体的な状況に応じて、本サービスを変更、中断、中止または終了する権利があり、プラットフォームはそのためにあなたにいかなる権利擁護または権利侵害の責任を負わない。
		</p>
		<h3>五、免責条項</h3>
		<p>
			1、本サービスは既存の技術と条件が達成できる現状に基づいて提供されており、プラットフォームは本サービスを提供し、サービスの一貫性と安全性を確保するために最善を尽くします。しかし、プラットフォームは、不可抗力、ネットワーク原因、第三者サービスの瑕疵などの原因でサービスが中断したり異常になったりする可能性があることを含むが、これらに限定されない、技術やその他のリスクを常に予見したり、防止したりすることはできません。このような状況が発生した場合、プラットフォームの最も大きなビジネス努力は改善されますが、これによってあなたや他の第三者にいかなる法的責任を負う義務はありません。
		</p>
		<p>2、プラットフォームは自らダウンタイムのメンテナンス、システムのアップグレード、調整を行う可能性があり、そのために本サービスを正常に使用できない場合は、プラットフォームが法的責任を負う必要がないことに同意します。</p>
		<p>3、いかなる場合においても、プラットフォームは間接的、懲罰的、偶発的、または刑罰的な損害賠償責任を負わない。また、原因や方法にかかわらず、プラットフォームが負担するすべての責任は、本の使用によって支払われる費用を超えません。
		</p>
		<h3>六、違約責任</h3>
		<p>
			1、本契約に違反した場合、プラットフォームは法律法規、関連契約及びプラットフォーム管理規定に基づいて、あなたの行為の具体的な状況及び危害の程度に応じて、あなたまたはあなたのアカウントに対して管理措置をとる権利があり、あなたのアカウント内の「糖果」を警告、差し引いたり、空にしたり、あなたのアカウントのすべてまたは一部の機能を制限したり、短期または永久にあなたのアカウントを閉鎖したりすることなどを含むが、これらに限らない。
		</p>
		<p>
			2、第三者があなたのアカウントを利用して違法な違反または本契約の約束に違反した行為を実施した疑いがある場合、潜在的な被害者および他のユーザーの合法的権益を保障し、プラットフォームの生態秩序を維持し、損害のさらなる拡大を防止するために、プラットフォームも本契約およびプラットフォームの関連規則に従ってあなたのアカウントを管理措置する権利があり、これに対してプラットフォームに法的責任を要求するべきではありません。
		</p>
		<p>
			3、プラットフォームの外で違法または不当な行為を実施した疑いがある場合、プラットフォームには本契約または使用可能なプラットフォームの他の規則（UniLiveユーザーサービス契約、コミュニティ自律条約など）に違反していると考える合理的な理由があり、プラットフォームにも本条第1項に約束された管理措置を取る権利があり、これに対してプラットフォームに法的責任を要求するべきではありません。
		</p>

	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/RechargeAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/RechargeAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/RechargeAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/RechargeAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/RechargeAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/RechargeAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/RechargeAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/RechargeAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/RechargeAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/RechargeAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/RechargeAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/RechargeAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>